@charset "utf-8";

.global-footer {
	background: rgba(var(--color00),0.05);
	padding: 5% 0 150px 0;
	margin-top: 3%;
}
.global-footer-inner {
	max-width: var(--base-width);
	margin: 0 auto;
	padding: 0;
}
.global-footer .siteid {
	margin-bottom: 5%;
	width: min(40%, 200px);
  padding: 0;
}
@media ( min-width: 1281px ) {
	.global-footer {
		padding: 5% 0 5% 0;
	}
}

@media ( min-width: 641px ) {
	.global-footer-inner {
		padding: 0 4vw;
	}
}
@media ( min-width: 1281px ) {
	.global-footer-inner {
		padding: 0;
	}
}


.footer-links > dl > dt {
	border-bottom: 1px solid rgba(var(--color00),0.5);
	padding: 1rem 0 1rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: min(4vw, 16px);
	color: rgba(var(--color00),0.85);
	font-weight: 700;
}
.footer-links > dl > dd > dl > dt {
	font-size: min(4vw, 14px);
	font-weight: 700;
	padding: 1rem 0 1rem 0;
	color: rgba(var(--color00),0.85);
}
@media ( min-width: 641px ) {
	.footer-links {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.footer-links > dl {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	.footer-links > dl.row1 {
		width: calc( 100% / 2 - 2% );
	}
	.footer-links > dl > dd {
		padding: 1rem 0 0 0;
	  width: 100%;
	}
	.footer-links > dl > dd ul {
		margin-bottom: 2em;
		/* display: flex; */
		flex-wrap: wrap;
	}
	.footer-links ul li {
		padding: .35em 0;
	}
	.footer-links ul li a {
		color: rgba(var(--color00),0.75);
		font-weight: 700;
		font-size: min(4vw, 14px);
	}
	.footer-links ul li a:hover {
		color: rgba(var(--color00),1);
	}
	.footer-links ul li a::before {
		content: '\0030fb';
	}
	.footer-links > dl.row2 > dd > ul > li {
		width: 50% !important;
		padding: .25em 0;
	}
	.footer-links > dl > dd > dl > dd li {
		width: 50%;
	}
	.footer-links > dl.row1 {
		width: calc( 100% / 2 - 2% );
	}
	.footer-links > dl.row2 {
		width: 100%;
	}
	.footer-links > dl.colfull {
		margin-bottom: 3rem;
		width: 100%;
	}
	.footer-links > dl.colfull dd {
		letter-spacing: -.05em;
		line-height: 2;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.footer-links > dl.colfull dd a {
		color: rgba(var(--color00),0.75);
		letter-spacing: 0;
		font-weight: 700;
		font-size: min(3vw, 12px);
		padding: 0 1em 0 0.5em;
		margin-right: 1.5em;
		display: inline-block;
		position: relative;
	}
	.footer-links > dl.colfull dd a:hover {
		color: rgba(var(--color00),1);
	}
	.footer-links > dl.colfull dd a::after {
		content: '\00ff5c';
		position: absolute;
		left: 100%;
	}
}
@media ( min-width: 1281px ) {
	.footer-links dl.row1 {
		width: calc( 100% / 5 - 0.5% );
	}
	.footer-links dl.row2 {
		width: calc( 100% / 5 * 2 - 0.5% );
	}
}
@media ( max-width: 640px ) {
	.footer-links {
		border-bottom: 1px solid rgba(var(--color00),0.2);
		margin-bottom: 5%;
	}
	.footer-links > dl {
		border-top: 1px solid rgba(var(--color00),0.2);
	}
	.footer-links > dl > dt {
		padding: 1rem 4vw;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: none;
	}
	.footer-links > dl > dt::after {
		content: '';
		height: min(4vw, 24px);
		width: min(4vw, 24px);
		display: block;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z" fill="%23333"/></svg>');
		transition: transform 0.5s ease;
	}
	.footer-links > dl.open dt::after {
		transform: scale(1, -1);
	}
	.footer-links > dl > dd > dl {
		margin-top: 1rem;
	}
	.footer-links > dl > dd > dl > dt {
		padding: 1rem 4vw;
		border-bottom: 1px solid rgba(var(--color00),0.2);
	}
	.footer-links > dl > dd {
		display: block;
    height: 0;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    font-size: min(3vw, 14px);
    transition: all .75s ease;
	}
	.footer-links > dl.open > dd {
		height: auto;
		opacity: 1;
		align-items: flex-start;
		transition: all .75s ease;
		padding-bottom: 1rem;
		border-top: 1px solid rgba(var(--color00),0.2);
	}
	.footer-links > dl > dd ul {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;
	}
	.footer-links > dl > dd ul + ul {
		margin-top: 1rem;
		border-top: 1px solid rgba(var(--color00),0.2);
	}
	.footer-links > dl > dd ul li {
		width: calc( 100% / 2 );
		display: flex;
    align-items: center;
		margin-top: 0;
    flex: 0 0 auto;
		border-bottom: 1px solid rgba(var(--color00),0.2);
	}
	.footer-links > dl > dd ul li:nth-of-type(odd) {
		border-right: 1px solid rgba(var(--color00),0.2)
	}
	.footer-links > dl > dd ul li a {
		padding: 1rem 4vw;
		width: 100%;
		font-size: min(4vw,14px);
		color: rgba(var(--color00),0.75);
		letter-spacing: 0;
		font-weight: 700;
	}
	.footer-links > dl.colfull > dd {
		padding: 0 4vw;
	}
	.footer-links > dl.open.colfull > dd {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 1rem 4vw;
	}
	.footer-links > dl.open.colfull > dd a {
		flex: 0 0 calc(100% / 2);
		padding: 0.5em 0.5em;
		font-size: min(4vw,14px);
		color: rgba(var(--color00),0.75);
		letter-spacing: 0;
		font-weight: 700;
	}
}


.article.single .global-footer {
	padding: 0;
}
.article.single .global-footer .global-footer-inner {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
}
.article.single .global-footer .global-footer-inner .siteid {
	margin: 0.5rem auto;
	width: 100%;
	max-width: min(80%,200px);
	display: flex;
	justify-content: center;
	padding: 0;
}
.article.single .footer-links {
	display: none;
}
@media ( min-width: 641px ) {
	.article.single .global-footer .global-footer-inner {
		flex-direction: row;
		padding: 0.75rem 4vw;
		justify-content: space-between;
	}
	.article.single .global-footer .global-footer-inner .siteid {
		flex-grow: 0;
		margin: 0 auto;
		padding: 0;
	}
}
@media ( min-width: 1281px ) {
	.article.single .global-footer .global-footer-inner {
		padding: 0.75rem 0;
	}
}

.copyright {
	text-align: center;
	font-size: min(3vw, 12px);
	color: rgba(var(--color00),0.85);
}
.article.single .global-footer .global-footer-inner .copyright {
	padding: 0.5rem 0;
}
@media ( min-width: 641px ) {
	.article.single .global-footer .global-footer-inner .copyright {
		flex-grow: 1;
		text-align: right;
	}
}
